import React from "react"

// Components
import Layout from "../../../components/layout"
import Band, { BandColors } from "../../../components/band"
import SEO from "../../../components/seo"
import FlowDataTableTopsNav from "../../../components/top-nav/flow-top-nav"

// CSS
import styles from "./flow-data-table.module.css"
import shared from "../../../shared/shared.module.css"
import grid from "../../../shared/bootstrap-grid.module.css"

// SVG
import FlowDataTable from "../../../svg/FlowDataTable/FlowDataTable.svg"
import FlowDataTableSortSelect from "../../../svg/FlowDataTable/FlowDataTableSortSelect.svg"
import FlowDataTableEdit from "../../../svg/FlowDataTable/FlowDataTableEdit.svg"
import FlowDataTableDBEdit from "../../../svg/FlowDataTable/FlowDataTableDBEdit.svg"

// Icons
import Check from "../../../icons/check.svg"

const flowTierData = [
  {
    title: 'Read Only Mode',
    desc: 'View a list of records in the data table.',
    Image: FlowDataTable
  },
  {
    title: 'Sort & Select Mode',
    desc: `
      Sort and select the records in the table. Selected
      records can be accessed in subsequent steps of
      the flow.
    `,
    Image: FlowDataTableSortSelect
  },
  {
    title: 'In Memory Edit Mode',
    desc: `
      Provides the ability to edit records in addition to the Sort & Select features.
      The data table does not store the edited records in the Salesforce database. Instead 
      the data table provides an output property which allows subsequent steps of the flow 
      to process the edited records. 
    `,
    Image: FlowDataTableEdit
  },
  {
    title: 'Real Time Database Edit Mode',
    desc: `
      Provides the ability to edit records and the data table will save the changes to the 
      Salesforce database real time. This mode also has all the features of Sort & Select.  
      Along with saving the records to the database the edited records will also be available
      for subsequent steps of the flow.
    `,
    Image: FlowDataTableDBEdit
  }
];

const FlowTier = ({ title, desc, maxRows, price, Image }) => (
  <div className={`${grid.row} ${grid.justifyContentBetween}`}>
    <div className={`${grid.colLg6}`}>
      <h2>{title}</h2>
      <p>{desc}</p>
    </div>
    <div className={`${grid.colLg5} ${grid.mt4} ${grid.mtLg0}`}>
      <Image className={shared.w100} />
    </div>
  </div>
);

const FlowTiers = () => flowTierData.map((tier, i) => (
  <>
    <FlowTier
      title={tier.title}
      desc={tier.desc}
      maxRows={tier.maxRows}
      price={tier.price}
      Image={tier.Image}
    />
    {i < flowTierData.length - 1 &&
      <hr className={styles.tierDivider} />
    }
  </>
));

const TableCheck = ({ asterisk }) => (
  <div className={styles.tableCheck}>
    <Check />
    {asterisk && <span className={styles.checkAsterisk}>*</span>}
  </div>
);

const FeaturesTableDesktop = () => (
  <table>
    <thead>
      <tr>
        <th>&nbsp;</th>
        <th width="20%">Free</th>
        <th width="20%">Pro</th>
        <th width="20%">Ultimate</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Read Only Mode</td>
        <td><TableCheck /></td>
        <td><TableCheck /></td>
        <td><TableCheck /></td>
      </tr>
      <tr>
        <td>Sort & Select Mode</td>
        <td>&nbsp;</td>
        <td><TableCheck /></td>
        <td><TableCheck /></td>
      </tr>
      <tr>
        <td>In Memory Editing Mode</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td><TableCheck /></td>
      </tr>
      <tr>
        <td>Database Editing Mode</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td><TableCheck /></td>
      </tr>
      <tr>
        <td>Show/Hide Row Numbers</td>
        <td><TableCheck /></td>
        <td><TableCheck /></td>
        <td><TableCheck asterisk={true} /></td>
      </tr>
      <tr>
        <td>Control Table Height <span className={styles.small}>(pixels)</span></td>
        <td><TableCheck /></td>
        <td><TableCheck /></td>
        <td><TableCheck /></td>
      </tr>
      <tr>
        <td>Select Mode <span className={styles.small}>(checkbox or radio buttons)</span></td>
        <td>&nbsp;</td>
        <td><TableCheck /></td>
        <td><TableCheck /></td>
      </tr>
      <tr>
        <td>Sort Feature <span className={styles.small}>(on or off)</span></td>
        <td>&nbsp;</td>
        <td><TableCheck /></td>
        <td><TableCheck /></td>
      </tr>
      <tr>
        <td>Clip or Wrap Text</td>
        <td><TableCheck /></td>
        <td><TableCheck /></td>
        <td><TableCheck /></td>
      </tr>
      <tr>
        <td>Initial Column Widths <span className={styles.small}>(pixels)</span></td>
        <td><TableCheck /></td>
        <td><TableCheck /></td>
        <td><TableCheck /></td>
      </tr>
      <tr>
        <td>Maximum Records</td>
        <td>100</td>
        <td>500</td>
        <td>500</td>
      </tr>
      <tr>
        <td>Pricing</td>
        <td>Free</td>
        <td>$0.99 <div className={styles.small}>per user per month</div></td>
        <td>$1.99 <div className={styles.small}>per user per month</div></td>
      </tr>
    </tbody>
  </table>
);

const FeaturesTableMobile = () => (
  <div>

    {/* FREE TABLE */}
    <table>
      <thead>
        <tr className={styles.mobileTableHeader}>
          <th colspan="2">Free</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Read Only Mode</td>
          <td><TableCheck /></td>
        </tr>
        <tr>
          <td>Sort & Select Mode</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>In Memory Editing Mode</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>Database Editing Mode</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>Field Set Controls Field Display</td>
          <td><TableCheck /></td>
        </tr>
        <tr>
          <td>Show/Hide Row Numbers</td>
          <td><TableCheck /></td>
        </tr>
        <tr>
          <td>Control Table Height <span className={styles.small}>(pixels)</span></td>
          <td><TableCheck /></td>
        </tr>
        <tr>
          <td>Select Mode <span className={styles.small}>(checkbox or radio buttons)</span></td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>Sort Feature <span className={styles.small}>(on or off)</span></td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>Maximum Records</td>
          <td>100</td>
        </tr>
        <tr>
          <td>Pricing</td>
          <td>Free</td>
        </tr>
      </tbody>
    </table>

    {/* PRO TABLE */}
    <table>
      <thead>
        <tr className={styles.mobileTableHeader}>
          <th colspan="2">Pro</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Read Only Mode</td>
          <td><TableCheck /></td>
        </tr>
        <tr>
          <td>Sort & Select Mode</td>
          <td><TableCheck /></td>
        </tr>
        <tr>
          <td>In Memory Editing Mode</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>Database Editing Mode</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>Field Set Controls Field Display</td>
          <td><TableCheck /></td>
        </tr>
        <tr>
          <td>Show/Hide Row Numbers</td>
          <td><TableCheck /></td>
        </tr>
        <tr>
          <td>Control Table Height <span className={styles.small}>(pixels)</span></td>
          <td><TableCheck /></td>
        </tr>
        <tr>
          <td>Select Mode <span className={styles.small}>(checkbox or radio buttons)</span></td>
          <td><TableCheck /></td>
        </tr>
        <tr>
          <td>Sort Feature <span className={styles.small}>(on or off)</span></td>
          <td><TableCheck /></td>
        </tr>
        <tr>
          <td>Maximum Records</td>
          <td>500</td>
        </tr>
        <tr>
          <td>Pricing</td>
          <td>$0.99 <div className={styles.small}>per user per month</div></td>
        </tr>
      </tbody>
    </table>

    {/* ULTIMATE TABLE */}
    <table>
      <thead>
        <tr className={styles.mobileTableHeader}>
          <th colspan="2">Ultimate</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Read Only Mode</td>
          <td><TableCheck /></td>
        </tr>
        <tr>
          <td>Sort & Select Mode</td>
          <td><TableCheck /></td>
        </tr>
        <tr>
          <td>In Memory Editing Mode</td>
          <td><TableCheck /></td>
        </tr>
        <tr>
          <td>Database Editing Mode</td>
          <td><TableCheck /></td>
        </tr>
        <tr>
          <td>Field Set Controls Field Display</td>
          <td><TableCheck /></td>
        </tr>
        <tr>
          <td>Show/Hide Row Numbers</td>
          <td><TableCheck asterisk={true} /></td>
        </tr>
        <tr>
          <td>Control Table Height <span className={styles.small}>(pixels)</span></td>
          <td><TableCheck /></td>
        </tr>
        <tr>
          <td>Select Mode <span className={styles.small}>(checkbox or radio buttons)</span></td>
          <td><TableCheck /></td>
        </tr>
        <tr>
          <td>Sort Feature <span className={styles.small}>(on or off)</span></td>
          <td><TableCheck /></td>
        </tr>
        <tr>
          <td>Maximum Records</td>
          <td>500</td>
        </tr>
        <tr>
          <td>Pricing</td>
          <td>$1.99 <div className={styles.small}>per user per month</div></td>
        </tr>
      </tbody>
    </table>
  </div>
);

const FlowDataTableFeaturesPage = () => (
  <Layout>
    <SEO title="Flow Data Table" keywords={[`tigerface`, `systems`, `salesforce`, `products`, `flow data table`]} />

    <FlowDataTableTopsNav />

    <Band>
      <h1>Features</h1>
      <p>
        Flow data table is highly configurable and offers the following table modes:
      </p>

      <div className={grid.mt5}>
        <FlowTiers />
      </div>
    </Band>

    <Band color={BandColors.gray}>
      <h2>Pricing</h2>
      <p>Flow Data Table is offered in three tiers:</p>
      <div className={`${grid.mt5} ${shared.hiddenMobile}`}>
        <FeaturesTableDesktop />
      </div>
      <div className={`${grid.mt5} ${shared.hiddenDesktop}`}>
        <FeaturesTableMobile />
      </div>
      <div className={`${styles.small}`}>
        * If one or more fields in the data table are editable then row numbers cannot be turned off.
      </div>
    </Band>
  </Layout>
);

export default FlowDataTableFeaturesPage;